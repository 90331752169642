import {SlConfig} from '../interfaces/SlConfig';

export const PetHealthCfg: SlConfig = {
  name: 'PetHealth Vet Clinic',
  locale: 'en',
  apiKeys: {
    serviceLocation: {
      pqa: ['Swy-T82IWdrQObU7YwuzLE1D', 'I75qcNHkao-ZagxFqs2MBc3c'],
      pit: ['ZjKsI7JkILL1pJSQBPFcaU4D', 'yc7XK-GEJqKA10OlTGJ3sNS4']
    },
    implementation: {
      pqa: 'HS2QxhwTnWFdvvpcEBDwzJ1Y',
      pit: 'TjzWE1azTfntNJEU4LlhtFfQ'
    }
  },
  ids: {
    serviceLocation: {
      pqa: ['_75_43b0e8c575a04114835c65f3d2b71def', '_75_59c246e4895441c4ac407e2f348e9031'],
      pit: ['_73_5c1fe27559f143b88c926f9082c1fcdc', '_73_2aa3787deca44dd0820d15a8c1f66042']
    },
    implementation: {
      pqa: '_1b2ecb3d1fa04071a986b2f0b3e59cf5',
      pit: '_1b2ecb3d1fa04071a986b2f0b3e59cf5'
    }
  },
  behaviors: {
    triggerSimpleCollections: false
  },
  address: {
    verifiedByManagementSystem: {
      verified: false,
      supportsSignature: false,
    },
    country: 'US',
    streetAddress1: '345 McFarland Parkway',
    streetAddress3: 'Alpharetta, GA',
    postalCode: '30004',
  },
  email: {
    verifiedByManagementSystem: {
      verified: false
    },
    value: 'staff@pethealth.com'
  },
  website: 'https://mywebsite.com',
  timezone: 'America/Los_Angeles',
  namespace: '_HXMS-',
  guid: 'pit123',
  metadata: {
    selectorLabel: 'Loyalty',
    selections: [
      'Platinum',
      'Gold',
      'Silver'
    ],
    dataKeyName: 'loyaltyProgram',
    externalId: 'da21d5f9-0d9c-4929-8343-5c017680c787',
  },
  forms: [
    {
      name: 'General Consent',
      id: 'XMS-GeneralConsent',
      selected: false,
      entries: [
        {
          kbiId: 'generalConsentMarkdown',
          value: 'Sign below if you agree to all the terms.'
        }
      ]
    },
    {
      name: 'Covid-19 Screening',
      id: 'XMS-Covid19',
      selected: false,
    },
    {
      name: 'Demographics',
      id: 'XMS-Demographics',
      selected: false
    }
  ]
};
