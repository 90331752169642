import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {TokenAndPop} from '../interfaces/forms/TokenAndPop';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenAndPopService {

  private formsBaseUrl = environment.baseUrl.forms;
  private statementsBaseUrl = environment.baseUrl.statements;

  constructor(private http: HttpClient) { }

  getFormsPortalLink(apiKey: string, payload: TokenAndPop): Observable<TokenAndPop> {
    const modifiedHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'CX-API-KEY': apiKey
    });
    const url = `${this.formsBaseUrl}/cx/v1/ui/instances`;
    return this.http.post<TokenAndPop>(url, payload, {headers: modifiedHeaders});
  }


  getStatementsConfigLink(apiKey: string, payload: TokenAndPop): Observable<TokenAndPop> {
    const modifiedHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'CX-API-KEY': apiKey
    });
    const url = `${this.statementsBaseUrl}/ui/instances`;
    return this.http.post<TokenAndPop>(url, payload, {headers: modifiedHeaders});
  }
}
