import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {DataStoreService} from '../services/data-store.service';
import {StatementService} from '../services/statement.service';
import {MessageService} from '../services/message.service';
import {MessageTypes} from '../interfaces/message';
import {fade} from '../animations';
import {StatementRequest} from '../interfaces/Statement';
import {Customer} from '../interfaces/Customer';
import {Balance} from '../interfaces/Balance';
import {MatTableDataSource} from '@angular/material/table';
import {HttpClient} from '@angular/common/http';
import {interval, Subscription, timer} from 'rxjs';
import {PaymentRecord} from '../interfaces/PaymentRecord';
import {CustomerRequest} from '../interfaces/CustomerRequest';
import {LineItemsService} from '../services/line-items.service';
import {QueuePackageService} from '../services/queue-package.service';
import {QueuePackageAction} from '../interfaces/queue-package/QueuePackageAction';
import {QueuePackageItem} from '../interfaces/queue-package/QueuePackageItem';


@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss'],
  animations: [
    fade
  ]
})
export class StatementsComponent implements OnInit, OnDestroy {

  pollSource = interval(5000);
  pollSub: Subscription;
  collectionsSource = timer(65000);
  collectionsSub: Subscription;

  balancesTableColumns: string[] = ['Title', 'Date', 'Metadata', 'Balance'];
  balancesDataSource: MatTableDataSource<Balance>;

  showCustomer = false;
  showBalances = false;
  showAddCustomer = true;

  activeServiceLocation = this.dataStore.getActiveServiceLocation();

  metaData = this.activeServiceLocation.metadata;
  metaDataValue = this.activeServiceLocation.metadata.selections[0];

  qrCode: any;

  selectedCustomer: CustomerRequest;

  customer: Customer = {
    firstName: '',
    lastName: '',
    customerId: '',
    statementPaid: false,
    balanceDue: 0,
    phoneNumber: '',
    email: '',
  };


  invoices: Balance[] = [];
  customers: Customer[] = [
    this.customer
  ];

  newStatement: StatementRequest = {
    currency: 'USD',
    totalAmount: 0,
    statementDateTime: '',
    customerMessage: 'Thank you for your business!',
    dueInFullDateTime: '',
    attachments: [
      {
        contentType: 'application/pdf',
        content: '',
      }
    ],
    lineItems: [],
    myMetadata: {
      externalId: '',
      data: {
        [this.activeServiceLocation.metadata.dataKeyName]: ''
      }
    },
    _embedded: {
      customer: {
        id: '',
      },
      serviceLocation: {
        id: '',
      }
    }
  };

  constructor(private data: DataService,
              private dataStore: DataStoreService,
              private statementService: StatementService,
              private messageService: MessageService,
              private http: HttpClient,
              private lineItemsService: LineItemsService,
              private queuePackageService: QueuePackageService) { }

  ngOnInit() {
    this.getPdfAttachment();
    this.selectedCustomer = this.dataStore.getStatementsCustomer();
  }


  ngOnDestroy() {
    if (this.pollSub !== undefined ) {
      this.pollSub.unsubscribe();
    }
    if (this.collectionsSub !== undefined) {
      this.collectionsSub.unsubscribe();
    }
  }


  getPdfAttachment(): void {
    this.http.get('assets/attachments/base64_statement.txt', {responseType: 'text'})
      .subscribe(pdf => {
        this.newStatement.attachments[0].content = pdf;
      });
  }


  prepStatement(sendMessage = true): void {
    const slId = this.dataStore.getServiceLocationId();
    this.newStatement._embedded.customer.id = this.selectedCustomer.id;
    this.newStatement._embedded.serviceLocation.id = slId;
    this.newStatement.statementDateTime = new Date().toISOString();
    this.newStatement.totalAmount = (this.customer.balanceDue * 100);
    this.newStatement.myMetadata.data[this.activeServiceLocation.metadata.dataKeyName] = this.metaDataValue;
    this.newStatement.myMetadata.externalId = 'statement-'
      + this.activeServiceLocation.metadata.externalId + '-' + this.selectedCustomer.primaryEmail.value;

    this.newStatement.lineItems = [];
    if (this.newStatement.totalAmount > 0) {
      this.newStatement.lineItems = this.lineItemsService.getLineItems();
    }


    // expire the statement immediately for simple collections
    if (this.dataStore.getTriggerCollections()) {
      this.newStatement.dueInFullDateTime = this.newStatement.statementDateTime;
    } else {
      const whenDue = new Date();
      whenDue.setDate(whenDue.getDate() + 1);
      this.newStatement.dueInFullDateTime = whenDue.toISOString();
    }

    // override config for Update Balance action
    if (!sendMessage) {
      this.newStatement.configOverrides = {
        on_setStatement_sendMessage_enabled: false
      };
    } else {
      this.newStatement.configOverrides = null;
    }

    this.sendStatement(sendMessage);
  }


  sendStatement(sendMessage = true): void {
    this.statementService.addStatement(this.dataStore.getApiKey(), this.newStatement)
      .subscribe(
        statement => {
          // store the statement Id
          this.dataStore.setStatementId(statement.id);
          this.qrCode = statement.headers.get('location-as-navigable-url-as-qr-code');

          // Handle Statement creation with 'Statement Reminders' enabled in XMS Config
          if (this.newStatement.totalAmount !== 0 && this.dataStore.getTriggerCollections() && sendMessage) {
            this.messageService.addMessage('Statement Created', MessageTypes.Success);
            this.triggerReminder();
            this.checkStatementStatus();

            // Handle Statement creation
          } else if (this.newStatement.totalAmount !== 0 && sendMessage) {
            this.messageService.addMessage('Statement Created', MessageTypes.Success);
            this.checkStatementStatus();

            // Handle Balance Update creation (no communication sent to end consumer)
          } else if (!sendMessage) {
            this.messageService.addMessage('Balance Updated', MessageTypes.Success);
          }

          this.newStatement.totalAmount = 0;
        },
        () => {
          this.messageService.addMessage('Could not Create Statement', MessageTypes.Error);
        }
      );
  }


  updateTotal(price: number): void {
    this.newStatement.totalAmount = (this.newStatement.totalAmount + price);
  }


  checkStatementStatus(): void {
    // Don't allow multiple concurrent polling timers
    if (this.pollSub) {
      this.pollSub.unsubscribe();
    }

    this.pollSub = this.pollSource.subscribe(() => {
      this.queuePackageService.checkForItems(this.dataStore.getApiKey()).subscribe(
        resp => {
          if (resp.numberOfItemsInQueue > 0) {
            this.getPaymentDetails(resp.queuePackagePickupUrl, this.selectedCustomer.id);
          }
        },
        err => {
          console.log('Queue Package Home failed. Details in console');
          console.log(err);
          this.pollSub.unsubscribe();
        }
      );
    });
  }


  getPaymentDetails(url: string, customerId): void {
    this.queuePackageService.pickupItems(this.dataStore.getApiKey(), url).subscribe(
      resp => {
        if (resp.items.length > 0) {
          resp.items.forEach(item => {
            if (item.name === 'onSetPayment' && item.payload._embedded.customer.id === customerId && item.payload.capturedAmount > 0) {
              this.handlePayment(item, resp.queuePackagePostUrl);
            }
          });
        }
      }, err => {
        console.log('Queue Package Pickup failed. Details in console');
        console.log(err);
        this.pollSub.unsubscribe();
      }
    );
  }


  handlePayment(item: QueuePackageItem, postUrl: string): void {
    // internal accounting, notification, and statement balance update
    this.messageService.addMessage('Payment received for customer: ' + this.customer.firstName + ' '
      + this.customer.lastName + '', MessageTypes.Success);
    this.addPayment(item.payload);
    this.prepStatement(false);

    // Stop polling once balance hits $0.00
    if (this.customer.balanceDue === 0) {
      this.pollSub.unsubscribe();
    }

    // remove handled item from the queue
    const actions: QueuePackageAction[] = [
        {
          name: 'updateQueue',
          version: '1',
          body: {
            itemId: item.id,
            status: 'HANDLED'
          }
        }
    ];

    this.queuePackageService.updateQueue(this.dataStore.getApiKey(), postUrl, actions).subscribe(
      resp => {
        if (resp.actionResponses.length > 0) {
          resp.actionResponses.forEach(action => {
            if (action.name === 'updateQueue' && action.body.itemId === item.id) {
              if (action.body.status !== 'HANDLED') {
                this.messageService.addMessage('Item not removed from queue. Details in console.', MessageTypes.Error);
                console.log(action);
              }
            }
          });
        }
      }, err => {
        console.log('Queue Package Action failed. Details in console');
        console.log(err);
      }
    );
  }


  updateBalanceDue(balance: number): void {
    this.customer.balanceDue = this.customer.balanceDue - balance;
  }


  updateBalance(): void {
    this.customer.balanceDue = 315.00;
    this.newStatement.totalAmount = 315.00;
    // clear line items paid
    this.dataStore.setPaidLineItems([]);
    this.dataStore.setDynamicPartials(false);

    const date = new Date();
    const formattedDate = date.toLocaleDateString() + ' '
                          + date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute: '2-digit'});

    const newInvoice: Balance = {
      id: 'Statement Balance',
      date: formattedDate,
      balanceDue: 315.00
    };

    this.addToInvoices(newInvoice);
    this.showBalances = true;

    this.prepStatement(true);
  }


  addPayment(paymentMessage: PaymentRecord): void {
    this.customer.balanceDue = this.customer.balanceDue - ((paymentMessage.capturedAmount) / 100);

    // Enable or disable dynamic partial payments if criteria are met
    if (paymentMessage.capturedAmount > 0 && paymentMessage.paidLineItemReferences === undefined) {
      this.dataStore.setDynamicPartials(true);
    } else {
      this.dataStore.setDynamicPartials(false);
      this.lineItemsService.getLineItems(paymentMessage.paidLineItemReferences);
    }

    const date = new Date();
    const formattedDate = date.toLocaleDateString() + ' '
                          + date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute: '2-digit'});

    const newPayment: Balance = {
      id: 'Statement Balance',
      date: formattedDate,
      metadata: this.activeServiceLocation.metadata.selectorLabel + ': '
      + paymentMessage._embedded.itemsPaid[0].myMetadata.data[(this.activeServiceLocation.metadata.dataKeyName)],
      balanceDue: this.customer.balanceDue,
    };

    this.addToInvoices(newPayment);
  }


  addToInvoices(invoice: Balance): void {
    this.invoices.push(invoice);
    this.balancesDataSource = new MatTableDataSource(this.invoices);
  }


  triggerReminder(): void {
    this.collectionsSub = this.collectionsSource.subscribe(() => {
      this.statementService.triggerSimpleCollections(this.dataStore.getApiKey()).subscribe(
        () => {
          this.messageService.addMessage('Statement Reminder Sent', MessageTypes.Success);
        },
        () => {
          this.messageService.addMessage('Could not send Statement Reminder', MessageTypes.Error);
        }
      );
    });
  }




}
