import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceLocation } from '../interfaces/forms/ServiceLocation';
import {CustomerRequest} from '../interfaces/CustomerRequest';
import {CX2FormInstance} from '../interfaces/forms/FormInstance';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  private baseUrl = environment.baseUrl.forms;
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) { }


  addServiceLocation(apiKey: string, payload: ServiceLocation): Observable<ServiceLocation> {
    const modifiedHeaders = this.headers.set('CX-API-KEY', apiKey);
    const url = `${this.baseUrl}/cx1.0/action/setServiceLocation`;
    return this.http.post<ServiceLocation>(url, payload, {headers: modifiedHeaders});
  }

  addCustomer(apiKey: string, payload: CustomerRequest): Observable<CustomerRequest> {
    const modifiedHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'CX-API-KEY': apiKey
    });
    const url = `${this.baseUrl}/cx1.0/action/setCustomer`;
    return this.http.post<CustomerRequest>(url, payload, {headers: modifiedHeaders});
  }

  createFormForCustomer(apiKey: string, payload: CX2FormInstance): Observable<CX2FormInstance> {
    const modifiedHeaders = this.headers.set('CX-API-KEY', apiKey).set('Location-As-Navigable-Url', 'true');
    const url = `${this.baseUrl}/cx/action/createForm`;
    return this.http.post<CX2FormInstance>(url, payload, {headers: modifiedHeaders});
  }
}
