import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  @Input() header: string;
  @Input() message: string;
  @Input() trueButtonLabel: string;
  @Input() falseButtonLabel: string;
  @Output() selection = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }


  updateSelection(selection: boolean): void {
    this.selection.emit(selection);
  }

}
