import { Injectable, Input } from '@angular/core';
import { Message, MessageTypes } from '../interfaces/message';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  message: Message = {
    type: undefined,
    message: undefined
  };


  constructor() { }


  addMessage(newMessage: string, messageType: MessageTypes): void {
    this.message = {
      type: messageType,
      message: newMessage
    };
  }


  clearMessages(): void {
    this.message = {
      type: undefined,
      message: undefined
    };
  }
}
