import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {DataStoreService} from '../services/data-store.service';
import {MessageService} from '../services/message.service';
import {MessageTypes} from '../interfaces/message';
import {fade} from '../animations';
import {Customer} from '../interfaces/Customer';
import {MatTableDataSource} from '@angular/material/table';
import {HttpClient} from '@angular/common/http';
import {interval, Subscription, timer} from 'rxjs';
import {CustomerRequest} from '../interfaces/CustomerRequest';
import {QueuePackageService} from '../services/queue-package.service';
import {QueuePackageAction} from '../interfaces/queue-package/QueuePackageAction';
import {QueuePackageItem} from '../interfaces/queue-package/QueuePackageItem';
import {CheckInRequest} from '../interfaces/CheckInRequest';
import {CheckInService} from '../services/check-in.service';


@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss'],
  animations: [
    fade
  ]
})
export class CheckInComponent implements OnInit, OnDestroy {

  pollSource = interval(5000);
  pollSub: Subscription;
  collectionsSource = timer(65000);
  collectionsSub: Subscription;

  checkInTableColumns: string[] = ['Status', 'Date'];
  checkInDataSource: MatTableDataSource<CheckInRequest>;

  checkInRequests: CheckInRequest[] = [];

  showCheckInRequests = false;
  selectedCustomer: CustomerRequest;

  qrCode: any;

  checkInRequest: CheckInRequest = {
    status: '',
    date: '',
    _embedded: {
      customer: {
        id: '',
      }
    }
  };

  constructor(private data: DataService,
              private dataStore: DataStoreService,
              private checkInService: CheckInService,
              private messageService: MessageService,
              private http: HttpClient,
              private queuePackageService: QueuePackageService) { }

  ngOnInit() {
    this.selectedCustomer = this.dataStore.getStatementsCustomer();
  }

  ngOnDestroy() {
    if (this.pollSub !== undefined ) {
      this.pollSub.unsubscribe();
    }
    if (this.collectionsSub !== undefined) {
      this.collectionsSub.unsubscribe();
    }
  }

  requestCheckIn(): void {
    this.checkInRequest._embedded.customer.id = this.selectedCustomer.id;

    this.checkInService.askToCheckIn(this.dataStore.getApiKey(), this.checkInRequest).subscribe(
      checkIn => {
        this.qrCode = checkIn.headers.get('location-as-navigable-url-as-qr-code');
        this.messageService.addMessage('Check in request sent!', MessageTypes.Success);
        this.checkCheckInStatus();
        this.updateCheckInRequest('Check in request sent');
        this.showCheckInRequests = true;
      },
      () => {
        this.messageService.addMessage('Could not Create Check In', MessageTypes.Error);
      }
    );
  }

  checkCheckInStatus(): void {
    // Don't allow multiple concurrent polling timers
    if (this.pollSub) {
      this.pollSub.unsubscribe();
    }

    this.pollSub = this.pollSource.subscribe( () => {
      this.queuePackageService.checkForItems(this.dataStore.getApiKey()).subscribe(
        resp => {
          if (resp.numberOfItemsInQueue > 0) {
            this.getCheckInDetails(resp.queuePackagePickupUrl, this.selectedCustomer.id);
          }
        },
        err => {
          console.log('Queue Package Home failed in request check in. Details in console.');
          console.log(err);
          this.pollSub.unsubscribe();
        }
      );
    });
  }

  getCheckInDetails(url: string, customerId): void {
    this.queuePackageService.pickupItems(this.dataStore.getApiKey(), url).subscribe(
      resp => {
        if (resp.items.length > 0) {
          resp.items.forEach(item => {
            if (item.name === 'onUpdatePresenceStatus' && item.payload._embedded.customer.id === customerId) {
              this.handleCheckInRequest(item, resp.queuePackagePostUrl);
            }
          });
        }
      },
      err => {
        console.log('Queue Package Pickup failed in request check in. Details in console.');
        console.log(err);
        this.pollSub.unsubscribe();
      }
    );
  }

  handleCheckInRequest(item: QueuePackageItem, postUrl: string): void {

    this.pollSub.unsubscribe();

    // remove handled item from the queue
    const actions: QueuePackageAction[] = [
      {
        name: 'updateQueue',
        version: '1',
        body: {
          itemId: item.id,
          status: 'HANDLED'
        }
      }
    ];

    this.queuePackageService.updateQueue(this.dataStore.getApiKey(), postUrl, actions).subscribe(
      resp => {
        if (resp.actionResponses.length > 0) {
          resp.actionResponses.forEach(action => {
            if (action.name === 'updateQueue' && action.body.itemId === item.id) {
              if (action.body.status !== 'HANDLED') {
                this.messageService.addMessage('Item not removed from queue. Details in console.', MessageTypes.Error);
                console.log(action);
              }

              this.messageService.addMessage('Check in received for ' + this.selectedCustomer.givenName + ' ' +
                this.selectedCustomer.familyName, MessageTypes.Success);
              this.updateCheckInRequest('Checked in');
            }
          });
        }
      }, err => {
        console.log('Queue Package Action failed. Details in console');
        console.log(err);
      }
    );
  }

  updateCheckInRequest(status: string): void {
    const date = new Date();
    const formattedDate = date.toLocaleDateString() + ' '
      + date.toLocaleTimeString(navigator.language, {hour: '2-digit', minute: '2-digit'});

    const newStatus: CheckInRequest = {
      status,
      date: formattedDate,
      _embedded: null
    };

    this.addToStatus(newStatus);
  }

  addToStatus(status: CheckInRequest) {
    this.checkInRequests.push(status);
    this.checkInDataSource = new MatTableDataSource(this.checkInRequests);
  }
}
