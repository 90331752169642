import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DevPortalMaterialModule } from './material';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, SafePipe } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigComponent } from './config/config.component';
import { StatementsComponent } from './statements/statements.component';
import { HeaderComponent } from './header/header.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MessageComponent } from './message/message.component';
import { CustomersComponent } from './customers/customers.component';
import { SelectedCustomerComponent } from './selected-customer/selected-customer.component';
import { FormsComponent } from './forms/forms.component';
import { TokenAndPopComponent } from './token-and-pop/token-and-pop.component';
import { ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {FinishedFormDialogComponent} from './forms/FinishedFormDialog/finished-form-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { CheckInComponent } from './check-in/check-in.component';
import {MatInputModule} from "@angular/material/input";
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    ConfigComponent,
    StatementsComponent,
    HeaderComponent,
    MainMenuComponent,
    MessageComponent,
    CustomersComponent,
    SelectedCustomerComponent,
    FormsComponent,
    FinishedFormDialogComponent,
    TokenAndPopComponent,
    SafePipe,
    ConfirmDialogComponent,
    CheckInComponent,
    LoginComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        DevPortalMaterialModule,
        MatDialogModule,
        NgxWebstorageModule.forRoot(),
        HttpClientModule,
        ReactiveFormsModule,
        MatInputModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
