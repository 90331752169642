

<mat-card @fadeOut *ngIf="messageService.message.type === 0 && messageService.message" class="alertMessage" id="successMessage" alertcard>
    <div class="iconHolder" id="successIconHolder">
        <span class="alertIcon" id="successIcon">
            <i class="fas fa-check-circle"></i>
      </span>
    </div>
    <mat-card-title class="alertTitle" id="successTitle">Success!</mat-card-title>
    <mat-card-content class="alertContent">{{messageService.message.message}}</mat-card-content>
    <span class="exitIcon" (click)="closeAlert()">
        <i class="fas fa-times-circle"></i>
    </span>
</mat-card>

<mat-card @fadeOut *ngIf="messageService.message.type === 1 && messageService.message" class="alertMessage" id="infoMessage" alertcard>
    <div class="iconHolder" id="infoIconHolder">
        <span class="alertIcon" id="infoIcon">
          <i class="fas fa-info-circle"></i>
      </span>
    </div>
    <mat-card-title class="alertTitle" id="infoTitle">Information</mat-card-title>
    <mat-card-content class="alertContent">{{messageService.message.message}}</mat-card-content>
    <span class="exitIcon" (click)="closeAlert()">
        <i class="fas fa-times-circle"></i>
    </span>
</mat-card>

<mat-card @fadeOut *ngIf="messageService.message.type === 2 && messageService.message" class="alertMessage" id="warningMessage" alertcard>
    <div class="iconHolder" id="warningIconHolder">
        <span class="alertIcon" id="warningIcon">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
    </div>
    <mat-card-title class="alertTitle" id="warningTitle">Warning</mat-card-title>
    <mat-card-content class="alertContent">{{messageService.message.message}}</mat-card-content>
    <span class="exitIcon" (click)="closeAlert()">
        <i class="fas fa-times-circle"></i>
    </span>
</mat-card>

<mat-card @fadeOut *ngIf="messageService.message.type === 3 && messageService.message" class="alertMessage" id="errorMessage" alertcard>
    <div class="iconHolder" id="errorIconHolder">
        <span class="alertIcon" id="errorIcon">
          <i class="fas fa-exclamation-circle"></i>
        </span>
    </div>
    <mat-card-title class="alertTitle" id="errorTitle">Error!</mat-card-title>
    <mat-card-content class="alertContent">{{messageService.message.message}}</mat-card-content>
    <span class="exitIcon" (click)="closeAlert()">
        <i class="fas fa-times-circle"></i>
    </span>
</mat-card>
