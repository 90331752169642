import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CheckInService {

  private baseUrl = environment.baseUrl.statements;

  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) { }


  askToCheckIn(apiKey: string, payload: object): Observable<any> {
    const modifiedHeadders = this.headers.set('CX-API-KEY', apiKey).set('Location-As-Navigable-Url', 'true');
    const url = `${this.baseUrl}/action/askToCheckIn`;
    return this.http.post<any>(url, payload, {headers: modifiedHeadders, observe: 'response'});
  }
}
