<div class="cover" *ngIf="showConfirmDialog"></div>

<div class="config-view" @fade>

  <h2 class="config-view-title">Configuration</h2>
    <mat-form-field style="width:300px">
      <mat-label>Persona</mat-label>
      <mat-select [(value)]="selectedIndex" (selectionChange)="personaChanged($event.value)" >
        <mat-option *ngFor="let key of apiKeyValues; let i = index" [value]="i">
          {{key}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  <br>
  <app-token-and-pop *ngIf="tokenAndPopShown" [desiredAction]="actions.administerServiceLocation"></app-token-and-pop>

    <app-confirm-dialog *ngIf="showConfirmDialog"
                        [header]="confirmHeader" [message]="confirmMessage"
                        [trueButtonLabel]="confirmTrueButtonLabel"
                        (selection)="toggleConfirmDialog()"
    ></app-confirm-dialog>

  <br>
  <p class="no-reply-toggle" (click)="popPIConfig()">
    Get Implementation TinyLink
    <i class="fas fa-external-link-alt"></i>
  </p>
  <br>
  <br>
</div>
