<br>
<br>

<div class="form-types-container">
  <mat-label>Form Types</mat-label>
  <br>
  <br>
  <table mat-table class="form-selection-table" [dataSource]="slConfig.forms">

    <ng-container matColumnDef="formTypes">
      <td mat-cell class="option-cell" *matCellDef="let element">
        <span class="form-types-title">{{element.name}}</span>
        <div class="buttons-container">
          <button type="button" class="submitButton" (click)="assignForm(element)" [disabled]="sentForms.has(element)">Assign Form</button>
          <button type="button" class="submitButton responseButton" (click)="openFormResponseDialog(element)" [disabled]="!hasFormReceivedResponse(element)">Show Response</button>
        </div>
      </td>
    </ng-container>

    <tr mat-row class="option-row" *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>

