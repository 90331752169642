import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import { DataStoreService } from './services/data-store.service';
import {DomSanitizer} from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'customer-experience';

  constructor(private dataStoreService: DataStoreService, private httpClient: HttpClient) {}

  async ngOnInit() {
    const appConfig: any = await this.httpClient.get('/appConfig').toPromise();
    this.dataStoreService.setEnvironment(appConfig.environment);
    this.saveApiKey(appConfig.environment);
  }

  readLocalStorage(key) {
    return localStorage.getItem(key);
  }

  saveApiKey(environment: string): void {
    switch (environment) {
      case 'PIT':
        this.dataStoreService.setApiKey(this.dataStoreService.getActiveServiceLocation().apiKeys.serviceLocation.pit[0]);
        break;
      case 'PQA':
        this.dataStoreService.setApiKey(this.dataStoreService.getActiveServiceLocation().apiKeys.serviceLocation.pqa[0]);
        break;
      default:
        this.dataStoreService.setApiKey(this.dataStoreService.getActiveServiceLocation().apiKeys.serviceLocation.pqa[0]);
        break;
    }
  }
}
