
      <table class="new-customer-table">
        <tr>
          <td>
            <h2 class="add-customer-title">Create New Customer</h2>
          </td>
        </tr>
        <tr>
          <td>
            <input type="text" autocomplete="given-name" class="inputField registrationItemField" [(ngModel)]="newStatementsCustomer.givenName" placeholder="First Name">
          </td>
          <td>
            <mat-select class="country-picker" [(value)]="selectedCountry" placeholder="US" (selectionChange)="isValidPhoneNumber(phone, selectedCountry)">
              <mat-option class="country-picker-option" *ngFor="let country of countries" [value]="country.code">
                {{country.code}} - {{country.name}}
              </mat-option>
            </mat-select>
            <input type="tel"
            (keyup)="realTimeFormatPhoneNumber(selectedCountry)"
            (blur)="isValidPhoneNumber(phone, selectedCountry)"
            class="inputField registrationItemField phone-number"
            [ngClass]="{'invalid': !isValidPhone  }"
            [(ngModel)]="phone"
            placeholder="Phone Number">
          </td>
        </tr>
        <tr>
          <td>
            <input type="text" autocomplete="family-name" class="inputField registrationItemField" [(ngModel)]="newStatementsCustomer.familyName" placeholder="Last Name">
          </td>
          <td>
            <input type="text" autocomplete="email" class="inputField registrationItemField" [(ngModel)]="email"  placeholder="Email Address"
            (blur)="validateEmail(email)" [ngClass]="{'invalid': !isValidEmail}"
            >
          </td>
        </tr>
        <tr>
          <td>
            <br>
            <button type="button" class="submitButton create-customer-button" (click)="addCustomer()"
            [disabled]="(phone.length === 0  && email.length === 0)
            || (!isValidPhone && email.length === 0)
            || (!isValidEmail && phone.length === 0)
            || (!isValidPhone && !isValidEmail)
            || (isValidPhone && !isValidEmail)
            || (isValidPhone && email.length === 0)
            || (isValidEmail && !isValidPhone)"
            [ngClass]="{'disabled': (phone.length === 0  && email.length === 0)
            || (!isValidPhone && email.length === 0)
            || (!isValidEmail && phone.length === 0)
            || (!isValidPhone && !isValidEmail)
            || (isValidPhone && !isValidEmail)
            || (isValidPhone && email.length === 0)
            || (isValidEmail && !isValidPhone)}">Create</button>
          </td>
        </tr>
      </table>

