import { Injectable } from '@angular/core';
import {QueuePackage} from '../interfaces/queue-package/QueuePackage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {QueuePackageAction} from '../interfaces/queue-package/QueuePackageAction';

@Injectable({
  providedIn: 'root'
})
export class QueuePackageService {

  private baseUrl = environment.baseUrl.statements;
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) { }


  public checkForItems(apiKey: string): Observable<QueuePackage> {
    const modifiedHeaders = this.headers.set('CX-API-KEY', apiKey);
    const url = `${this.baseUrl}/queuepackage/home`;
    return this.http.get<QueuePackage>(url, {headers: modifiedHeaders});
  }

  // got C.O.R.S. when using the pickup url "as is".
  // recomposed the url to avoid changing the backend.
  public pickupItems(apiKey: string, url: string): Observable<QueuePackage> {
    const pickupUrl = new URL(url);
    const pickupPath = pickupUrl.pathname.replace('/cx', '');
    const finalPath = `${this.baseUrl}${pickupPath}`;
    const modifiedHeaders = this.headers.set('CX-API-KEY', apiKey);
    return this.http.get<QueuePackage>(finalPath, {headers: modifiedHeaders});
  }

  // got C.O.R.S. when using the action url "as is".
  // recomposed the url to avoid changing the backend.
  public updateQueue(apiKey: string, url: string, payload: QueuePackageAction[]): Observable<QueuePackage> {
    const actionUrl = new URL(url);
    const actionPath = actionUrl.pathname.replace('/cx', '');
    const finalPath = `${this.baseUrl}${actionPath}`;
    const modifiedHeaders = this.headers.set('CX-API-KEY', apiKey);
    const actionsPayload = {
      actions: payload
    };
    return this.http.post<QueuePackage>(finalPath, actionsPayload, {headers: modifiedHeaders});
  }
}
