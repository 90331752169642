import { Component, HostListener, OnInit, Input, OnDestroy, Host, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../services/message.service';
import { DataStoreService } from '../services/data-store.service';
import { MessageTypes } from '../interfaces/message';
import { fade, toggleBorder, toggleMenu } from '../animations';
import { AuthenticationService } from '../services/authentication.service';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  animations: [
    fade,
    toggleMenu,
    toggleBorder
  ]
})
export class MainMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  screenHeight: number;
  screenWidth: number;

  isShown = true;
  statusSubscription: Subscription;
  contentSubscription: Subscription;
  businessNameSubscription: Subscription;
  businessName: string;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.hideMenuOnMobile();
  }

  constructor(private messageService: MessageService,
              private dataStoreService: DataStoreService,
              private authenticationService: AuthenticationService) {
   }


  ngOnInit() {
    this.hideMenuOnMobile();
    // this.businessName = this.dataStoreService.getBusinessName();
    // if (this.businessName !== undefined && this.businessName !== null && this.businessName !== '') {
    //   console.log(this.businessName);
    //   this.dataStoreService.setBusinessName(this.businessName);
    // }

    this.businessNameSubscription = this.dataStoreService.businessName.subscribe(
      name => {

        this.businessName = name;
      }
    );

  }

  hideMenuOnMobile(): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 700) {
      this.isShown = false;
    }
  }

  ngAfterViewInit() {
    this.onResize();
  }

  logout() {
    this.authenticationService.logout();
  }


  ngOnDestroy() {}

  toggleMenu(): void {
    this.isShown = (this.isShown === false ? true : false);
  }
}
