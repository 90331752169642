import { animate, style, trigger, transition, state } from '@angular/animations';

export let fade = trigger('fade', [
  state('void', style({ opacity: 0 })),
  state('shown', style({ opacity: 1})),
  transition(':enter', [
    animate(200)
  ]),
  transition(':leave', [
    animate(100)
  ]),
]);

export let fadeOut = trigger('fadeOut', [
  state('void', style({ opacity: 0})),
  transition(':leave', [
    animate(200)
  ])
]);

export let toggleMenu = trigger('toggleMenu', [
  state('false', style({ left : '-236px' })),
  state('true', style({ left : '0px' })),
  transition('false <=> true', animate(200)),
]);

export let toggleBorder =  trigger('toggleBorder', [
  state('false', style({ 'border-right': 'none'})),
  state('true', style({ 'border-right': '1px solid white'})),
  transition('false <=> true', animate(200)),
]);

export let slideContactForm = trigger('slideContactForm', [
  state('false', style({'right': '-600px'})),
  state('true', style({'right': '0px'})),
  transition('false <=> true', animate(200)),
]);
