import {Injectable} from '@angular/core';
import {LineItem} from '../interfaces/line-item';
import {DataStoreService} from './data-store.service';

@Injectable({
  providedIn: 'root'
})
export class LineItemsService {

  service1: LineItem = {
    lineItemReference: 'SERVICE-1',
    date: '',
    description: 'Exam',
    amount: 25000
  };
  service2: LineItem = {
    lineItemReference: 'SERVICE-2',
    date: '',
    description: 'Grooming',
    amount: 5000
  };
  service3: LineItem = {
    lineItemReference: 'SERVICE-3',
    date: '',
    description: 'Nail Trim',
    amount: 1500
  };

  constructor(private dataStoreService: DataStoreService) { }


  getLineItems(itemsPaid: string[] = []): LineItem[] {
    const itemsAlreadyPaid = this.dataStoreService.getPaidLineItems();

    // add line items from most recent payment to array of line items from previous payments.
    itemsPaid.forEach(item => {
      if (itemsAlreadyPaid.indexOf(item) === -1) {
        itemsAlreadyPaid.push(item);
      }
    });

    const unpaidLineItems: LineItem[] = [
      this.service1,
      this.service2,
      this.service3
    ];


    if (this.dataStoreService.getDynamicPartials()) {
      return null;
    }

    // Remove any line items that have already been paid.
    itemsAlreadyPaid.forEach(item => {
      const index = unpaidLineItems.findIndex(element => element.lineItemReference === item);
      if (index !== undefined) {
        unpaidLineItems.splice(index, 1);
      }
    });

    // set line item dates in YYYY-MM-DD
    const d = new Date().toISOString().slice(0, 10);
    unpaidLineItems.forEach((item, i) => {
      unpaidLineItems[i].date = d;
    });

    // update paid line items array: clear out record of paid line items once all line items associated with a Statement have been paid
    if (unpaidLineItems.length === 0) {
      this.dataStoreService.setPaidLineItems([]);
    } else {
      this.dataStoreService.setPaidLineItems(itemsAlreadyPaid);
    }

    // return any remaining unpaid line items.
    return unpaidLineItems;
  }
}
