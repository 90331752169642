import { Component, OnInit } from '@angular/core';
import { AsYouType, CountryCode, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js/min';
import { Country } from '../interfaces/country';
import { CustomerRequest } from '../interfaces/CustomerRequest';
import { DataStoreService } from '../services/data-store.service';
import { StatementService } from '../services/statement.service';
import { MessageService } from '../services/message.service';
import { MessageTypes } from '../interfaces/message';
import { Router } from '@angular/router';
import {forkJoin} from 'rxjs';
import {FormsService} from '../services/forms.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  email = '';
  phone = '';
  isValidPhone = true;
  isValidEmail = true;
  selectedCountry: CountryCode;
  countries: Country[] = [
    {
      code: 'US',
      name: 'United States',
    },
    {
      code: 'AU',
      name: 'Australia',
    },
    {
      code: 'NZ',
      name: 'New Zealand',
    },
    {
      code: 'GB',
      name: 'Great Britain',
    }
  ];

  newStatementsCustomer: CustomerRequest = {
    familyName: '',
    givenName: '',
    locale: 'en',
    sex: 'MALE',
    dateOfBirth: '1988-12-14'
  };


  constructor(private dataStore: DataStoreService,
              private statementService: StatementService,
              private formsService: FormsService,
              private messageService: MessageService,
              private router: Router) { }

  ngOnInit(): void {
  }


  addCustomer(): void {
    this.prepCustomerRequest();

    const setCustomer = forkJoin({
      statements: this.statementService.addCustomer(this.dataStore.getApiKey(), this.newStatementsCustomer),
    });

    setCustomer.subscribe(
      results => {
        this.dataStore.setStatementsCustomer(results.statements);
        this.messageService.addMessage('Customer has been added', MessageTypes.Success);
        this.router.navigate(['customers/selected-customer']);
      },
      () => {
        this.messageService.addMessage('Could not add Customer to Service Location', MessageTypes.Error);
      }
    );
  }


  prepCustomerRequest(): void {
    this.prepPrimaryEmail();
    this.prepPrimaryPhone();
    this.prepExternalId(this.email);
  }


  prepPrimaryEmail(): void {
    this.validateEmail(this.email);
    if (!this.isValidEmail) {
      if (this.newStatementsCustomer.primaryEmail !== undefined) {
        delete this.newStatementsCustomer.primaryEmail;
      }
    } else {
      this.newStatementsCustomer.primaryEmail = {
        value: this.email,
      };
    }
  }


  validateEmail(email: string): void {
    if (email !== undefined && email !== '') {
      // tslint:disable-next-line:max-line-length
      const allowed = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.isValidEmail = allowed.test(email);
    } else {
      this.isValidEmail = true;
    }
  }


  isValidPhoneNumber(rawPhone: string,  code: CountryCode): boolean {
    if (rawPhone === undefined || rawPhone === '') {
      this.isValidPhone = true;
      return;
    }

    if (code === undefined || code === null) {
      code = 'US';
    }
    const parsedPhone = parsePhoneNumberFromString(rawPhone, code);
    this.phone = parsedPhone.format('INTERNATIONAL');
    if (parsedPhone && parsedPhone.isValid() === true) {
      this.isValidPhone = true;
      return true;
    } else {
      this.isValidPhone = false;
      return false;
    }
  }


  prepPrimaryPhone(): void {
    if (this.phone === '' || this.phone === undefined) {
      delete this.newStatementsCustomer.primaryPhone;
    } else {
      this.newStatementsCustomer.primaryPhone = {
        verifiedByManagementSystem: {
          verified: false,
          supportsSms: true,
          supportsMms: false,
          supportsFax: false,
          supportsVoice: false,
        },
        value: this.phone,
      };
    }
  }


  realTimeFormatPhoneNumber(code: CountryCode): void {
    if (code === undefined || code === null) {
      code = 'US';
    }
    this.phone = new AsYouType({defaultCountry: code}).input(this.phone);
  }


  prepExternalId(id: string): void {
    this.newStatementsCustomer.myMetadata = {
      externalId: 'customer-' + this.dataStore.getActiveServiceLocation().metadata.externalId + '-' + id
    };
  }
}
