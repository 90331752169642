
<app-message></app-message>
<div *ngIf="readLocalStorage('token') != null">
    <app-header></app-header>
    <app-main-menu></app-main-menu>
</div>
<div class="main-display">
<router-outlet ></router-outlet>
</div>

