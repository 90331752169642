import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '../services/message.service';
import { MessageTypes } from '../interfaces/message';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { fade } from '../animations';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    fade
  ]
})
export class HeaderComponent implements OnInit {


  constructor(public messageService: MessageService,
              private router: Router) { }


  ngOnInit() {}
}
