import { Component, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { MessageService } from '../services/message.service';
import { Observable, Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { fadeOut } from '../animations';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  animations: [
    fadeOut
  ],
})


export class MessageComponent implements OnInit, DoCheck, OnDestroy {

  private subscription: Subscription;
  private timer: Observable<any>;

  constructor(public messageService: MessageService,
              private router: Router) { }

  ngOnInit() {
  }


  ngOnDestroy() {
    if (this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }


  ngDoCheck() {
    if (this.messageService.message.message && this.messageService.message.type !== 4) {
      this.setTimer();
    }
  }

  setTimer(): void {
    this.timer = timer(4000);
    this.subscription = this.timer.subscribe(() => {
      this.closeAlert();
    });
  }


  closeAlert(): void {
    this.messageService.clearMessages();
  }
}
