<div class="selected-customer-container">
  <h2 class="selected-customer-name">{{selectedCustomer.givenName}} {{selectedCustomer.familyName}}</h2>
  <p class="selected-customer-info">email: {{selectedCustomer.primaryEmail.value}}</p>
  <p class="selected-customer-info" *ngIf="selectedCustomer.primaryPhone">phone: {{selectedCustomer.primaryPhone.value}}</p>
  <p class="selected-customer-info" *ngIf="selectedCustomer.dateOfBirth">Date of Birth:
    {{selectedCustomer.dateOfBirth}}</p>
</div>

<mat-tab-group class="selected-customer-record">
  <mat-tab label="Ledger">
    <app-statements></app-statements>
  </mat-tab>
  <mat-tab label="Forms">
    <app-forms></app-forms>
  </mat-tab>
  <mat-tab label="Check In">
    <app-check-in></app-check-in>
  </mat-tab>
</mat-tab-group>
