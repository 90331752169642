import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatementService {

  private baseUrl = environment.baseUrl.statements;

  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) { }


  addServiceLocation(apiKey: string, payload: object): Observable<any> {
    const modifiedHeaders = this.headers.set('CX-API-KEY', apiKey);
    const url = `${this.baseUrl}/action/setServiceLocation`;
    return this.http.post<any>(url, payload, {headers: modifiedHeaders});
  }


  addCustomer(apiKey: string, payload: object): Observable<any> {
    const modifiedHeaders = this.headers.set('CX-API-KEY', apiKey);
    const url = `${this.baseUrl}/action/setCustomer`;
    return this.http.post<any>(url, payload, {headers: modifiedHeaders});
  }


  addStatement(apiKey: string, payload: object): Observable<any> {
    const modifiedHeaders = this.headers.set('CX-API-KEY', apiKey).set('Location-As-Navigable-Url', 'true');
    const url = `${this.baseUrl}/action/setStatement`;
    return this.http.post<any>(url, payload, {headers: modifiedHeaders, observe: 'response'});
  }


  triggerSimpleCollections(apiKey: string): Observable<any> {
    const modifiedHeaders = this.headers.set('CX-API-KEY', apiKey);
    const url = `${this.baseUrl}/internal/dev/statementReminder/triggerStatementReminderJob`;
    return this.http.post(url, null, {headers: modifiedHeaders, responseType: 'text'});
  }
}
