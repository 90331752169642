<mat-card class="confirm-dialog mat-elevation-z4">
    <h3 class="confirm-dialog-header">{{header}}</h3>
    <p class="confirm-dialog-message" [innerHTML]="message"></p>
    <div class="confirm-dialog-button-container">
        <button type="button"
        (click)="updateSelection(false)"
        class="cancel-button"
        >{{falseButtonLabel}}</button>
        <button type="button"
        (click)="updateSelection(true)"
        class="submitButton confirm-button"
        >{{trueButtonLabel}}</button>
    </div>
</mat-card>