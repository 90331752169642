import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';
import { CustomerRequest } from '../interfaces/CustomerRequest';
import {ServiceLocation} from '../interfaces/forms/ServiceLocation';
import {PetHealthCfg} from '../service-locations/pet-health';
import {SlConfig} from '../interfaces/SlConfig';


@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  private slName = new BehaviorSubject<string>('PetHealth Vet Clinic');
  public readonly businessName = this.slName.asObservable();

  private serviceLocation = new BehaviorSubject<SlConfig>(PetHealthCfg);

  private paidLineItems: string[] = [];

  constructor(private sessionStorage: SessionStorageService) { }


  public setApiKey(apiKey: string): void {
    this.sessionStorage.store('ApiKey', apiKey);
  }


  public getApiKey(): string {
    return this.sessionStorage.retrieve('ApiKey');
  }


  public setTriggerCollections(trigger: boolean): void {
    this.sessionStorage.store('triggerSimpleCollections', trigger);
  }


  public getTriggerCollections(): boolean {
    return this.sessionStorage.retrieve('triggerSimpleCollections');
  }


  public setDynamicPartials(dynamicPartials: boolean): void {
    this.sessionStorage.store('dynamicParials', dynamicPartials);
  }


  public getDynamicPartials(): boolean {
    return this.sessionStorage.retrieve('dynamicParials');
  }


  public setStatementId(statementId: string): void {
    this.sessionStorage.store('StatementID', statementId);
  }


  public getStatementId(): string {
    return this.sessionStorage.retrieve('StatementID');
  }


  public setServiceLocationId(id: string): void {
    this.sessionStorage.store('ServiceLocationID', id);
  }


  public getServiceLocationId(): string {
    return this.sessionStorage.retrieve('ServiceLocationID');
  }


  public setBusinessName(name: string): void {
    this.sessionStorage.store('ServiceLocationName', name);
    this.slName.next(name);
  }


  public getBusinessName(): string {
    return this.sessionStorage.retrieve('ServiceLocationName');
  }


  public setStatementsCustomer(customer: CustomerRequest): void {
    this.sessionStorage.store('statementsCustomer', customer);
  }


  public getStatementsCustomer(): CustomerRequest {
    return this.sessionStorage.retrieve('statementsCustomer');
  }


  public setFormsCustomer(customer: CustomerRequest): void {
    this.sessionStorage.store('formsCustomer', customer);
  }


  public getFormsCustomer(): CustomerRequest {
    return this.sessionStorage.retrieve('formsCustomer');
  }


  public setFormSL(sl: ServiceLocation): void {
    this.sessionStorage.store('formsSL', sl);
  }


  public getPaidLineItems(): string[] {
    return this.paidLineItems;
  }


  public setPaidLineItems(items: string[]): void {
    this.paidLineItems = items;
  }


  getActiveServiceLocation(): SlConfig {
    return this.serviceLocation.getValue();
  }

  public setEnvironment(env: string) {
    this.sessionStorage.store('environment', env);
  }

  public getEnvironment(): string {
    return this.sessionStorage.retrieve('environment');
  }

  public setSelectedIndex(selectedIndex: number) {
    this.sessionStorage.store('selectedIndex', selectedIndex);
  }

  public getSelectedIndex(): number {
    return this.sessionStorage.retrieve('selectedIndex');
  }
}
