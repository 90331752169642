<div class="statements-view">
  <div class="customer-view">
    <mat-label>Balance</mat-label>
    <br>
    <p class="inputField registrationItemField" id="amount-due" readonly="true"
       [innerText]="'$' + customer.balanceDue + '.00'"></p>
    <div class="qr-container">
      <img src="{{qrCode}}"/>
    </div>
    <div class="metadata-selector-container">
      <mat-label>{{metaData.selectorLabel}}</mat-label>
      <br>
      <br>
      <mat-form-field class="inputField" appearance="none">
        <mat-select [(value)]="metaDataValue">
          <mat-option *ngFor="let choice of metaData.selections" [value]="choice">
            {{ choice }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="buttons-container">
      <button type="button" class="submitButton send-statement-button" (click)="updateBalance()">Update and Send</button>
    </div>
    <table *ngIf="showBalances" mat-table [dataSource]="balancesDataSource" class="balance-table">
      <ng-container matColumnDef="Title">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let balance">{{balance.id}}</td>
      </ng-container>
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let balance">{{balance.date}}</td>
      </ng-container>
      <ng-container matColumnDef="Metadata">
        <th mat-header-cell *matHeaderCellDef>Metadata</th>
        <td mat-cell *matCellDef="let balance">{{balance.metadata}}</td>
      </ng-container>
      <ng-container matColumnDef="Balance">
        <th mat-header-cell *matHeaderCellDef>Balance</th>
        <td mat-cell *matCellDef="let balance">${{balance.balanceDue}}.00</td>
      </ng-container>
      <tr mat-header-row class="customer-table-header" *matHeaderRowDef="balancesTableColumns"></tr>
      <tr mat-row class="customer-table-row" *matRowDef="let row;
                                                        let even = even;
                                                        columns: balancesTableColumns;"
                                                        [ngClass]="{gray: even}"></tr>
    </table>
  </div>
</div>
