import { Component, OnInit } from '@angular/core';
import { CustomerRequest } from '../interfaces/CustomerRequest';
import { DataStoreService } from '../services/data-store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-selected-customer',
  templateUrl: './selected-customer.component.html',
  styleUrls: ['./selected-customer.component.scss']
})
export class SelectedCustomerComponent implements OnInit {

  selectedCustomer: CustomerRequest;

  constructor(private dataStore: DataStoreService,
              private router: Router) { }

  ngOnInit(): void {
    this.selectedCustomer = this.dataStore.getStatementsCustomer();
    if (this.selectedCustomer === undefined || this.selectedCustomer === null) {
      this.router.navigate(['customers']);
    }
  }

}
