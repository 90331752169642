import {Component, OnInit} from '@angular/core';
import {FormsService} from '../services/forms.service';
import {DataStoreService} from '../services/data-store.service';
import {CX2FormInstance} from '../interfaces/forms/FormInstance';
import {FormType} from '../interfaces/forms/FormType';
import {MessageService} from '../services/message.service';
import {MessageTypes} from '../interfaces/message';
import {interval, Subscription} from 'rxjs';
import {QueuePackageService} from '../services/queue-package.service';
import {QueuePackageItem} from '../interfaces/queue-package/QueuePackageItem';
import {QueuePackageAction} from '../interfaces/queue-package/QueuePackageAction';
import {FinishedFormDialogComponent} from './FinishedFormDialog/finished-form-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  pollSource = interval(5000);
  pollSub: Subscription;

  slConfig = this.dataStore.getActiveServiceLocation();
  displayedColumns: string[] = ['formTypes'];

  sentForms = new Map<FormType, string>();
  formResponses = new Map<string, object>();

  constructor(private formsService: FormsService,
              private dataStore: DataStoreService,
              private messageService: MessageService,
              private queuePackageService: QueuePackageService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  assignForm(form: FormType): void {
    const formInstance: CX2FormInstance = {
      _embedded: {
        formType: {
          formTypeIdentifier: form.id
        },
        customer: {
          id: this.dataStore.getStatementsCustomer().id
        }
      }
    };

    this.formsService.createFormForCustomer(this.dataStore.getApiKey(), formInstance)
      .subscribe((result) => {
          this.formResponses.set(result.id, null);
          this.sentForms.set(form, result.id);
          this.checkFormStatus();
          this.messageService.addMessage(
            'Sent the form to the customer',
            MessageTypes.Success
          );
        },
        () => {
          this.messageService.addMessage(
            'Could not create a form for that customer',
            MessageTypes.Error,
          );
        });
  }

  checkFormStatus(): void {
    if (this.pollSub) {
      this.pollSub.unsubscribe();
    }

    this.pollSub = this.pollSource.subscribe(() => {
      this.queuePackageService.checkForItems(this.dataStore.getApiKey()).subscribe(
        resp => {
          if (resp.numberOfItemsInQueue > 0) {
            this.getFormDetails(resp.queuePackagePickupUrl, this.dataStore.getStatementsCustomer().id);
          }
        },
        err => {
          console.log('Queue Package Home failed. Details in console');
          console.log(err);
          this.pollSub.unsubscribe();
        }
      );
    });
  }

  getFormDetails(url: string, customerId): void {
    this.queuePackageService.pickupItems(this.dataStore.getApiKey(), url).subscribe(
      resp => {
        if (resp.items.length > 0) {
          resp.items.forEach(item => {
            if (item.name === 'onSaveForm' && this.formResponses.has(item.payload.formId)) {
              this.displayFormResponse(item, resp.queuePackagePostUrl);
              this.messageService.addMessage(
                'Received form response from customer',
                MessageTypes.Success
              );
            }
          });
        }
      }, err => {
        console.log('Queue Package Pickup failed. Details in console');
        console.log(err);
        this.pollSub.unsubscribe();
      }
    );
  }

  displayFormResponse(item: QueuePackageItem, postUrl: string): void {
    console.log(item);
    this.formResponses.set(item.payload.formId, this.parseFormResponseData(item.payload.data));
    console.log(this.formResponses);

    // remove handled item from the queue
    const actions: QueuePackageAction[] = [
      {
        name: 'updateQueue',
        version: '1',
        body: {
          itemId: item.id,
          status: 'HANDLED'
        }
      }
    ];

    this.queuePackageService.updateQueue(this.dataStore.getApiKey(), postUrl, actions).subscribe(
      resp => {
        if (resp.actionResponses.length > 0) {
          resp.actionResponses.forEach(action => {
            if (action.name === 'updateQueue' && action.body.itemId === item.id) {
              if (action.body.status !== 'HANDLED') {
                this.messageService.addMessage('Item not removed from queue. Details in console.', MessageTypes.Error);
                console.log(action);
              }
            }
          });
        }
      }, err => {
        console.log('Queue Package Action failed. Details in console');
        console.log(err);
      }
    );
  }

  parseFormResponseData(data: JSON): Map<string, string> {
    const parsedForm = new Map<string, string>();
    Object.keys(data).forEach((key) => {
      parsedForm.set(key, data[key]);
    });
    return parsedForm;
  }

  hasFormReceivedResponse(form: FormType): boolean {
    const formId = this.sentForms.get(form);
    if (formId && this.formResponses.get(formId)) {
      return true;
    }
    return false;
  }

  openFormResponseDialog(form: FormType): void {
    const dialogRef = this.dialog.open(FinishedFormDialogComponent, {
      width: '500px',
      data: this.formResponses.get(this.sentForms.get(form)),
    });
  }
}
