import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessageTypes } from '../interfaces/message';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private baseUrl = environment.baseUrl.statements;
  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService
  ) { }

  async login (username: string, password: string): Promise<Observable<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = this.baseUrl.replace("/cx", "/login");
    const loginSuccess = await this.http.post(url, {password: password}, {headers: headers}).toPromise().then(() => {
      return true;
    }, () => {
      return false;
    });

    if (loginSuccess) {
      localStorage.setItem("token", "loggedIn");
      this.router.navigate(['config']);
      return of(new HttpResponse({status: 200}));
    } else {
      this.messageService.addMessage("Login Failed", MessageTypes.Error);
      return of(new HttpResponse({status: 401}));
    }
  }

  logout(): void {
    localStorage.removeItem("token");
    this.router.navigate(['login'])
  }

  isUserLoggedIn(): boolean {
    if(localStorage.getItem("token") == "loggedIn") {
      return true;
    }
    return false;
  }
  
}
