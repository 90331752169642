import {Component, Input, OnInit} from '@angular/core';
import {TokenAndPopService} from '../services/token-and-pop.service';
import {TokenAndPop} from '../interfaces/forms/TokenAndPop';
import {DataStoreService} from '../services/data-store.service';
import {MessageService} from '../services/message.service';
import {MessageTypes} from '../interfaces/message';
import {TokenAndPopActions} from '../enums/TokenAndPopActions';

@Component({
  selector: 'app-token-and-pop',
  templateUrl: './token-and-pop.component.html',
  styleUrls: ['./token-and-pop.component.scss']
})
export class TokenAndPopComponent implements OnInit {

  slConfig = this.dataStore.getActiveServiceLocation();
  showFrame = false;
  url = '';
  @Input() desiredAction: TokenAndPopActions;


  actOnEntityReq: TokenAndPop = {
    action: 'actOnEntity',
    executingEntity: {
      userName: 'xmsUser',
      externalId: this.slConfig.namespace,
      emailAddress: ''
    },
    context: {
      _embedded: {
        entity: {
          id: ''
        }
      }
    }
  };

  adminSLReq: TokenAndPop = {
    action: 'administerServiceLocation',
    context: {
      _embedded: {
        serviceLocation: {
          id: ''
        }
      }
    }
  };

  constructor(private tokenAndPop: TokenAndPopService,
              private dataStore: DataStoreService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    switch (this.desiredAction) {
      case TokenAndPopActions.actOnEntity: {
        this.popFormsPortal();
        break;
      }
      case TokenAndPopActions.administerServiceLocation: {
        this.popSLConfig();
        break;
      }
    }
  }


  popFormsPortal(): void {
    this.actOnEntityReq.executingEntity.emailAddress = this.dataStore.getFormsCustomer().primaryEmail.value;
    this.actOnEntityReq.context._embedded.entity.id = this.dataStore.getFormsCustomer().id;

    this.tokenAndPop.getFormsPortalLink(this.dataStore.getApiKey(), this.actOnEntityReq)
    .subscribe(result => {
      this.url = result.location;
      this.showFrame = true;
    }, () => {
      this.messageService.addMessage('Token and Pop failed', MessageTypes.Error);
    });
  }


  popSLConfig(): void {
    this.adminSLReq.context._embedded.serviceLocation.id = this.dataStore.getServiceLocationId();
    this.tokenAndPop.getStatementsConfigLink(this.dataStore.getApiKey(), this.adminSLReq)
      .subscribe(result => {
        this.url = result.tinyLink;
        this.showFrame = true;
      }, () => {
        this.messageService.addMessage('Token and Pop failed', MessageTypes.Error);
      });
  }
}
