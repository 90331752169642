// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'LOCAL',
  // formsServiceLocationId: '_9_C_JyEchCOr2kt_ts3TXcjj-g',
  baseUrl: {
    // statements: 'https://tpd-sb-cx-pqa.3pointdata.com/cx',
    // forms: 'https://tpd-scale.3pointdata.com'
    statements: '/cx',
    forms: ''
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
