import { Component, OnInit } from '@angular/core';
import { DataStoreService } from '../services/data-store.service';
import { MessageService } from '../services/message.service';
import { MessageTypes } from '../interfaces/message';
import { fade } from '../animations';
import { StatementService } from '../services/statement.service';
import { FormsService } from '../services/forms.service';
import { ServiceLocation } from '../interfaces/forms/ServiceLocation';
import { TokenAndPopActions } from '../enums/TokenAndPopActions';
import {forkJoin} from 'rxjs';
import {TokenAndPop} from '../interfaces/forms/TokenAndPop';
import {TokenAndPopService} from '../services/token-and-pop.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
  animations: [
    fade
  ]
})
export class ConfigComponent implements OnInit {

  apiKey: string;
  triggerCollections: boolean;
  tokenAndPopShown = false;
  actions = TokenAndPopActions;
  activeServiceLocation = this.dataStoreService.getActiveServiceLocation();
  // Get all api keys and id's for both environments (PQA, PIT)
  apiKeys = this.activeServiceLocation.apiKeys.serviceLocation;
  serviceLocationIds = this.activeServiceLocation.ids.serviceLocation;
  selectedIndex = this.dataStoreService.getSelectedIndex() === null ? 0 : this.dataStoreService.getSelectedIndex();
  apiKeyValues = [
    'Transactions API',
    'Merchant Ware'
  ];

  showConfirmDialog = false;
  confirmHeader: string;
  confirmMessage: string;
  confirmTrueButtonLabel: string;

  statementsServiceLocation: ServiceLocation = {
    primaryEmail: this.activeServiceLocation.email,
    primaryAddress: this.activeServiceLocation.address,
    timeZone: this.activeServiceLocation.timezone,
    name: this.activeServiceLocation.name,
    locale: this.activeServiceLocation.locale,
    myMetadata: {
      externalId: this.activeServiceLocation.metadata.externalId
    }
  };

  formsServiceLocation: ServiceLocation = {
    id: '',
    name: this.activeServiceLocation.name,
    locale: this.activeServiceLocation.locale,
    primaryEmail: this.activeServiceLocation.email,
    primaryPhone: {
      verifiedByManagementSystem: {
        verified: false,
        supportsSms: false,
        supportsMms: false,
        supportsFax: false,
        supportsVoice: false
      },
      value: '+18007746462'
    },
    primaryAddress: this.activeServiceLocation.address,
    timeZone: this.activeServiceLocation.timezone,
    primaryWebsite: {
      value: this.activeServiceLocation.website
    },
    myMetadata: {
      externalId: this.activeServiceLocation.namespace + 'pqa123'
    }
  };

  constructor(private dataStoreService: DataStoreService,
              private messageService: MessageService,
              private statementService: StatementService,
              private formsService: FormsService,
              private tokenAndPopService: TokenAndPopService,
              private http: HttpClient) { }


  async ngOnInit() {
    await this.fetchFormsServiceLocationId();
    this.loadKeyAndIdFromEnvironment();

    // fetch config
    this.triggerCollections = this.dataStoreService.getTriggerCollections();
    if (this.triggerCollections === undefined || this.triggerCollections === null) {
      this.dataStoreService.setTriggerCollections(this.activeServiceLocation.behaviors.triggerSimpleCollections);
    }
    this.saveChanges(false);
  }

  // With multiple keys and ids for a service location per environment. Find the right one based on the selection.
  loadKeyAndIdFromEnvironment(): void {
    let serviceLocationId;

    switch (this.dataStoreService.getEnvironment()) {
      case 'PQA': {
        this.apiKey = this.apiKeys.pqa[this.selectedIndex];
        serviceLocationId = this.serviceLocationIds.pqa[this.selectedIndex];
        break;
      }
      case 'PIT': {
        this.apiKey = this.apiKeys.pit[this.selectedIndex];
        serviceLocationId = this.serviceLocationIds.pit[this.selectedIndex];
        break;
      }
      default: {
        this.apiKey = this.apiKeys.pqa[this.selectedIndex];
        serviceLocationId = this.serviceLocationIds.pqa[this.selectedIndex];
      }
    }

    this.saveSelectedKeyAndId(this.apiKey, serviceLocationId);
  }


  // Update the datastore with the currently selected api key and service location id
  saveSelectedKeyAndId(currentKey: string, currentServiceLocationId: string): void {
    this.dataStoreService.setApiKey(currentKey);
    this.dataStoreService.setServiceLocationId(currentServiceLocationId);
    this.dataStoreService.setSelectedIndex(this.selectedIndex);

    // Activate the new location
    this.saveChanges(false);
  }


  saveChanges(showStatus = true): void {
    this.setFormsExternalId();
    this.dataStoreService.setTriggerCollections(this.triggerCollections);
    if (!showStatus) {
      this.activateLocation(true, false);
    } else {
      this.activateLocation(true);
    }
  }

  async fetchFormsServiceLocationId() {
    const appConfig: any = await this.http.get('/appConfig').toPromise();
    this.formsServiceLocation.id = appConfig.formsServiceLocationId;

  }


  activateLocation(update: boolean, showStatus = true): void {
    const setSL = forkJoin({
      statementSL: this.statementService.addServiceLocation(this.apiKey, this.statementsServiceLocation),
    });

    setSL.subscribe(
      results => {
        this.dataStoreService.setServiceLocationId(results.statementSL.id);
        this.tokenAndPopShown = true;

        if (update && showStatus) {
          this.messageService.addMessage('Location Config Updated', MessageTypes.Success);
        } else if (!update && showStatus) {
          this.messageService.addMessage('Location Activated', MessageTypes.Success);
        }
      },
      () => {
        if (update && showStatus) {
         this.messageService.addMessage('Could not Update Location Config', MessageTypes.Error);
        } else if (!update && showStatus) {
         this.messageService.addMessage('Could not Activate Location', MessageTypes.Error);
        }
    });
  }


  setFormsExternalId(): void {
    switch (this.dataStoreService.getEnvironment()) {
      case 'PQA': {
        this.formsServiceLocation.myMetadata.externalId = this.activeServiceLocation.namespace + 'pqa123';
        break;
      }
      case 'PIT': {
        this.formsServiceLocation.myMetadata.externalId = this.activeServiceLocation.namespace + 'pit123';
        break;
      }
      default: {
        this.formsServiceLocation.myMetadata.externalId = this.activeServiceLocation.namespace + 'pqa123';
      }
    }
  }


  popPIConfig(): void {
    let implementationKey: string;
    let implementationId: string;

    switch (this.dataStoreService.getEnvironment()) {
      case 'PIT':
        implementationKey = this.activeServiceLocation.apiKeys.implementation.pit;
        implementationId = this.activeServiceLocation.ids.implementation.pit;
        break;
      case 'PQA':
        implementationKey = this.activeServiceLocation.apiKeys.implementation.pqa;
        implementationId = this.activeServiceLocation.ids.implementation.pqa;
        break;
      default:
        implementationKey = this.activeServiceLocation.apiKeys.implementation.pqa;
        implementationId = this.activeServiceLocation.ids.implementation.pqa;
    }


    const req: TokenAndPop = {
      action: 'administerPartnerImplementation',
      context: {
        _embedded: {
          implementation: {
            id: implementationId
          }
        }
      }
    };

    this.tokenAndPopService.getStatementsConfigLink(implementationKey, req)
      .subscribe(result => {
        this.confirmHeader = 'Open this link in a different browser!';
        this.confirmMessage = result.tinyLink;
        this.confirmTrueButtonLabel = 'Close';
        this.toggleConfirmDialog();
      }, () => {
        this.messageService.addMessage('Token and Pop failed', MessageTypes.Error);
      });
  }


  toggleConfirmDialog(): void {
    this.showConfirmDialog = !this.showConfirmDialog;
  }

  personaChanged(selectedIndex) {
    this.selectedIndex = selectedIndex;
    // Hide to refresh after new settings are loaded
    this.tokenAndPopShown = false;
    this.loadKeyAndIdFromEnvironment();
  }
}
