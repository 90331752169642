import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigComponent } from './config/config.component';
import { StatementsComponent } from './statements/statements.component';
import { CustomersComponent } from './customers/customers.component';
import { SelectedCustomerComponent } from './selected-customer/selected-customer.component';
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import { LoginComponent } from './login/login.component';
import { FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { AuthenticationGuard } from './guards/authentication.guard';

const routes: Routes = [
  {
    path: '', component: LoginComponent, pathMatch: 'full',
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'config',
    component: ConfigComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'customers/selected-customer',
    component: SelectedCustomerComponent,
    canActivate: [AuthenticationGuard]
  }
  /*
  {
    path: '**',
    redirectTo: 'config',
    pathMatch: 'full',
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class AppRoutingModule { }
