export const MOCKSERVICES = [
  {
    serviceName: 'Good Service 1',
    price: 3000,
  },
  {
    serviceName: 'Awesome Service 2',
    price: 10000,
  },
  {
    serviceName: 'Phenominal Service 3',
    price: 100000,
  }
];
