<main class="form-signin">
    <form [formGroup]="loginForm">
        <h1 class="h3 mb-3 fw-normal">Sign In</h1>
        <div class="form-floating">
            <input type="email" class="form-control" id="floatingInput" formControlName="username">
            <label for="floatingInput">Username</label>
            <div *ngIf="loginForm.invalid && usernameControl?.touched && usernameControl.errors">
            </div>
        </div>
        <div class="form-floating">
            <input type="password" class="form-control" id="floatingPassword" formControlName="password">
            <label for="floatingPassword">Password</label>
            <div *ngIf="loginForm.invalid && passwordControl?.touched && passwordControl.errors">
            </div>
        </div>
        <button class="w-100 btn btn-lg btn-primary" type="submit" (click)="login()">Sign in</button>
    </form>
</main>