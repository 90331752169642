<div class="check-in-view">
  <br>
  <div class="buttons-container">
    <button type="button" class="submitButton check-in-button" (click)="requestCheckIn()">Request Check In</button>
  </div>
  <div class="qr-container">
    <img src="{{qrCode}}"/>
  </div>
  <table *ngIf="showCheckInRequests" mat-table [dataSource]="checkInDataSource" class="check-in-table">
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let checkInRequest">{{checkInRequest.status}}</td>
    </ng-container>
    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let checkInRequest">{{checkInRequest.date}}</td>
    </ng-container>
    <tr mat-header-row class="customer-table-header" *matHeaderRowDef="checkInTableColumns"></tr>
    <tr mat-row class="customer-table-row" *matRowDef="let row;
                                                       let even = even;
                                                       columns: checkInTableColumns;"
                                                       [ngClass]="{gray: even}"></tr>
  </table>
</div>
