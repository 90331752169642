import { Injectable } from '@angular/core';
import { MOCKSERVICES } from '../mock-data/mock-services';
//import { MOCKCUSTOMERS } from '../mock-data/mock-customers';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private mockServices = new BehaviorSubject<any>(MOCKSERVICES);
  //private mockCustomers = new BehaviorSubject<any>(MOCKCUSTOMERS);

  constructor() { }

  /*
  getCustomers(): Observable<any> {
    return this.mockCustomers.asObservable();
  }
  */


  getServices(): Observable<any> {
    return this.mockServices.asObservable();
  }
}
