<div id="menuHeader" [@toggleBorder]="isShown">
  <span id="menuBars">
    <i class="fas fa-bars"  (click)='toggleMenu()'></i>
</span>
    <p id="menuTitle">{{businessName}}</p>
</div>
<ng-container>
    <div id="mainMenu" @fade [@toggleMenu]="isShown">
        <ul class="TopLevel">
          <li  class="TopLevelItem" routerLink="/customers">
            <a class="TopLevelLink" >Customers</a>
          </li>
          <li  class="TopLevelItem" routerLink="/config">
              <a class="TopLevelLink" >Config</a>
          </li>
          <li class="TopLevelItem">
            <button (click)="logout()">Logout</button>
          </li>
        </ul>
    </div>
</ng-container>
